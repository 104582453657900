<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2"><b>Step 2.</b> <i>Isolating the neutral comound.</i></p>

      <p>
        <v-select
          v-model="inputs.input1"
          class="my-1"
          style="display: inline-block; width: 12em"
          outlined
          :items="options"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        Which layer?
      </p>
      <!--image-->
      <p>(image placeholder)</p>
      <p>clean</p>
      <p>
        <v-select
          v-model="inputs.input2"
          class="my-1"
          style="display: inline-block; width: 12em"
          outlined
          :items="options"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          -> swirl for
        </v-select>
      </p>
      <p>
        <v-select
          v-model="inputs.input3"
          class="my-1"
          style="display: inline-block; width: 12em"
          outlined
          :items="options"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
      <p>
        Decant into a
        <v-select
          v-model="inputs.input4"
          class="my-1"
          style="display: inline-block; width: 12em"
          outlined
          :items="options"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        <chemical-latex content="25 mL" />
        vial and set aside in the
        <v-select
          v-model="inputs.input5"
          class="my-1"
          style="display: inline-block; width: 12em"
          outlined
          :items="options"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
      <p>
        Once the ether has evaporated, you will be left with a neutral compound. Determine the mass
        and take a
        <v-select
          v-model="inputs.input6"
          class="my-1"
          style="display: inline-block; width: 12em"
          outlined
          :items="options"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemUCI51LB_A1Q16b',
  components: {ChemicalLatex, StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
      },
      options: [
        {text: 'melting point', value: 'meltingPoint'},
        {text: 'bottom', value: 'bottom'},
        {text: '0.20 g unknown', value: '020unknown'},
        {text: 'MeOH', value: 'MeOH'},
        {text: '3 mL deionized (DI) water', value: 'deionizedWater'},
        {text: '-7 mL 1.5 M NaOH', value: 'NaOH'},
        {text: 'drying agent', value: 'dryingAgent'},
        {text: 'hood', value: 'hood'},
        {text: '5 min.', value: '5min'},
        {text: 'tared', value: 'tared'},
        {text: 'top layer', value: 'topLayer'},
        {text: 'diethyl ether', value: 'diethylEther'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
