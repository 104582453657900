var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-2"},[_c('b',[_vm._v("Step 2.")]),_vm._v(" "),_c('i',[_vm._v("Isolating the neutral comound.")])]),_c('p',[_c('v-select',{staticClass:"my-1",staticStyle:{"display":"inline-block","width":"12em"},attrs:{"outlined":"","items":_vm.options,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input1),callback:function ($$v) {_vm.$set(_vm.inputs, "input1", $$v)},expression:"inputs.input1"}}),_vm._v(" Which layer? ")],1),_c('p',[_vm._v("(image placeholder)")]),_c('p',[_vm._v("clean")]),_c('p',[_c('v-select',{staticClass:"my-1",staticStyle:{"display":"inline-block","width":"12em"},attrs:{"outlined":"","items":_vm.options,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input2),callback:function ($$v) {_vm.$set(_vm.inputs, "input2", $$v)},expression:"inputs.input2"}},[_vm._v(" -> swirl for ")])],1),_c('p',[_c('v-select',{staticClass:"my-1",staticStyle:{"display":"inline-block","width":"12em"},attrs:{"outlined":"","items":_vm.options,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input3),callback:function ($$v) {_vm.$set(_vm.inputs, "input3", $$v)},expression:"inputs.input3"}})],1),_c('p',[_vm._v(" Decant into a "),_c('v-select',{staticClass:"my-1",staticStyle:{"display":"inline-block","width":"12em"},attrs:{"outlined":"","items":_vm.options,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input4),callback:function ($$v) {_vm.$set(_vm.inputs, "input4", $$v)},expression:"inputs.input4"}}),_c('chemical-latex',{attrs:{"content":"25 mL"}}),_vm._v(" vial and set aside in the "),_c('v-select',{staticClass:"my-1",staticStyle:{"display":"inline-block","width":"12em"},attrs:{"outlined":"","items":_vm.options,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input5),callback:function ($$v) {_vm.$set(_vm.inputs, "input5", $$v)},expression:"inputs.input5"}})],1),_c('p',[_vm._v(" Once the ether has evaporated, you will be left with a neutral compound. Determine the mass and take a "),_c('v-select',{staticClass:"my-1",staticStyle:{"display":"inline-block","width":"12em"},attrs:{"outlined":"","items":_vm.options,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input6),callback:function ($$v) {_vm.$set(_vm.inputs, "input6", $$v)},expression:"inputs.input6"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }